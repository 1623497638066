import { AfterViewInit, Component, ElementRef, ViewChild, input, numberAttribute, output } from '@angular/core'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { fromEvent } from 'rxjs'
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'

import { IconFill } from '~common/icons'

@Component({
  selector: 'sb-search-bar',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatIconModule],
  template: `
    <div class="search-bar">
      <input #input type="search" [placeholder]="placeholder()" />
      <mat-icon [svgIcon]="IconsFill.Search" />
    </div>
  `,
  styles: [
    `
      @use 'sass:map';
      @use 'sass:color';
      @use 'themes/config/_palette.scss' as palette;

      :host {
        width: 100%;
      }

      .search-bar {
        position: relative;
        display: grid;
        height: 40px;

        input {
          all: unset;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          background-color: map.get(palette.$neutral, 50);
          border-radius: 4px;
          padding: 8px 36px 8px 16px;

          &:focus {
            background-color: color.adjust(map.get(palette.$neutral-variant, 700), $alpha: -0.88);
          }
        }

        mat-icon {
          position: absolute;
          height: 100%;
          right: 12px;
        }
      }
    `,
  ],
})
export class SearchBarComponent implements AfterViewInit {
  @ViewChild('input') input!: ElementRef
  placeholder = input<string>('Search')
  debounce = input(0, { transform: numberAttribute })
  search = output<string>()
  protected readonly IconsFill = IconFill

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'input')
      .pipe(
        filter(Boolean),
        debounceTime(this.debounce()),
        distinctUntilChanged(),
        tap(() => this.search.emit(this.input.nativeElement.value)),
      )
      .subscribe()
  }

  onInput(event: Event) {
    const target = event.target as HTMLInputElement
    this.search.emit(target.value)
  }
}
