import { Component, input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

import { IconFill } from '~common/icons'

@Component({
  selector: 'sb-channel-type-logo',
  standalone: true,
  imports: [MatIcon],
  template: `
    @switch (type()) {
      @case ('facebook') {
        <mat-icon [svgIcon]="Icons.Facebook" class="channel__type fb" />
      }
      @case ('instagram') {
        <mat-icon [svgIcon]="Icons.Instagram" class="channel__type ig" />
      }
      @case ('facebook-ad-account') {
        <mat-icon [svgIcon]="Icons.Campaign" class="channel__type fb-ads" />
      }
      @case ('linkedin-organization') {
        <mat-icon [svgIcon]="Icons.Linkedin" class="channel__type linkedin-organization" />
      }
    }
  `,
  styles: `
    .channel__type {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 16px;
      z-index: 3;
      background-color: white;

      &.ig {
        border-radius: 0.3rem;
      }

      &.fb {
        border-radius: 50%;
      }

      &.fb-ads {
        border-radius: 0.3rem;
      }

      &.linkedin-organization {
        border-radius: 0.2rem;
      }
    }
  `,
})
export class ChannelTypeLogoComponent {
  type = input<string>()
  protected readonly Icons = IconFill
}
