import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from 'src/app/common/components/confirm-dialog/confirm-dialog.component'

import { DialogService } from '~core/services/ui/dialog.service'

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  dialogRef: MatDialogRef<ConfirmDialogComponent>

  constructor(private dialogService: DialogService) {}

  confirm(title: string, content: string, cancel: string = null, confirm: string = null): Observable<boolean> {
    const initialState: ConfirmDialogData = { title, content, cancel, confirm }
    this.dialogRef = this.dialogService.open(ConfirmDialogComponent, { data: initialState, closeOnNavigation: true })

    return new Observable<boolean>(this.getConfirmSubscriber())
  }

  private getConfirmSubscriber() {
    return (observer) => {
      const subscription = this.dialogRef.afterClosed().subscribe((reason: string) => {
        observer.next(this.dialogRef.componentInstance.answer)
        observer.complete()
      })
      return {
        unsubscribe() {
          subscription.unsubscribe()
        },
      }
    }
  }
}
